import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import MapChart from "../components/MapChart";

const Team = (props) => {
  const { intro } = props.data;

  return (
    <Layout bodyClass="page-teams">
      <SEO title="Team" />
      <div className="intro">
        <div className="col-12 col-md-6 mb-2">
          <MapChart />
        </div>
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
              <div dangerouslySetInnerHTML={{ __html: intro.html }} />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <h1>Calgary</h1>
        <div className="row">
          <div className="col-12 col-md-6 mb-2">
            <div className="team team-summary team-summary-large">
              <div className="team-image">
                <img
                  alt="Calgary Retina Consultants"
                  className="img-fluid mb-2"
                  src="/images/team/crc.png"
                />
              </div>
              <div className="team-meta">
                <h2 className="team-name">Calgary Retina Consultants</h2>
                <ul className="team-description">
                  <li>Dr. Anna Ells</li>
                  <li>Dr. Michael Fielden</li>
                  <li>Dr. Amin Kherani</li>
                  <li>Dr. Geoff Williams</li>
                </ul>
              </div>
              <div className="team-content">
                <p>
                  <a target="_blank" href="https://www.calgaryretina.ca ">
                    Website
                  </a>
                </p>
                <div>#200, 5340 – 1st Street SW Calgary AB, T2H 0C8</div>
                <div>Office: 403-286-6802</div>
                <div>Fax: 403-286-6819</div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-2">
            <div className="team team-summary team-summary-large">
              <div className="team-image">
                <img
                  alt="Mitchell Eye Centre"
                  className="img-fluid mb-2"
                  src="https://picsum.photos/200"
                />
              </div>
              <div className="team-meta">
                <h2 className="team-name">Mitchell Eye Centre</h2>
                <ul className="team-description">
                  <li>Dr. Patrick Mitchell</li>
                  <li>Dr. Monique Munro</li>
                </ul>
              </div>
              <div className="team-content">
                <p>
                  <a target="_blank" href="https://mitchelleyecentre.com">
                    Website
                  </a>
                </p>
                <div>Suite 210</div>
                <div>5504 Macleod Trail SW Calgary AB T2H0J5</div>
                <div>office: 403 258 1773</div>
                <div>Fax: 403 258 2704</div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-2">
            <div className="team team-summary team-summary-large">
              <div className="team-image">
                <img
                  alt="Clearview Eye Centre"
                  className="img-fluid mb-2"
                  src="/images/team/clearview.png"
                />
              </div>
              <div className="team-meta">
                <h2 className="team-name">Clearview Eye Centre</h2>
                <ul className="team-description">
                  <li>Dr. Feisal Adatia</li>
                  <li>Dr. Monique Munro</li>
                </ul>
              </div>
              <div className="team-content">
                <p>
                  <a target="_blank" href="https://www.clearvieweyecentre.com">
                    Website
                  </a>
                </p>
                <div>Suite 100</div>
                <div>5340 1st Street SW Calgary AB T2H0C8</div>
                <div>Office: 403-253-1118</div>
                <div>Fax: 403-353-1138</div>
              </div>
            </div>
          </div>


          <div className="col-12 col-md-6 mb-2">
            <div className="team team-summary team-summary-large">
              <div className="team-image">
                <img
                  alt="Dr. Paul Savage"
                  className="img-fluid mb-2"
                  src="https://picsum.photos/200"
                />
              </div>
              <div className="team-meta">
                <h2 className="team-name">Dr. Paul Savage</h2>
                <ul className="team-description"></ul>
              </div>
              <div className="team-content">
                <div>7808 Elbow Drive Calgary Alberta T2V 1K4</div>
                <div>Office: 403-245-4442</div>
                <div>Fax: 403-229-1757</div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-2">
            <div className="team team-summary team-summary-large">
              <div className="team-image">
                <img
                  alt="Dr. Kevin Warrian"
                  className="img-fluid mb-2"
                  src="/images/team/kevin_warrian.jpg"
                />
              </div>
              <div className="team-meta">
                <h2 className="team-name">Dr. Kevin Warrian</h2>
                <ul className="team-description"></ul>
              </div>
              <div className="team-content">
                <div>450, 4935 - 40th Ave, NW</div>
                <div>Office: 403-286-3022</div>
                <div>Fax: 403-286-2943</div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-2">
            <div className="team team-summary team-summary-large">
              <div className="team-image">
                <img
                  alt="Jaime Claramunt"
                  className="img-fluid mb-2"
                  src="/images/team/jaime_claramunt.jpeg"
                />
              </div>
              <div className="team-meta">
                <h2 className="team-name">Jaime Claramunt</h2>
                <ul className="team-description"></ul>
              </div>
              <div className="team-content">
                <div>11510 Westgate Dr Unit 104, Grande Prairie, AB T8V 4E9</div>
                <div>Phone: (780) 402-8001</div>
                <div>Fax: (780) 402-8030</div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-2">
            <div className="team team-summary team-summary-large">
              <div className="team-image">
                <img
                  alt="Henry Fung"
                  className="img-fluid mb-2"
                  src="/images/team/dr-fung.png"
                />
              </div>
              <div className="team-meta">
                <h2 className="team-name">Henry Fung</h2>
                <ul className="team-description"></ul>
              </div>
              <div className="team-content">
                <p>
                  <a target="_blank" href="https://cavieye.com/">
                    Website
                  </a>
                </p>
                <div>3215 49 Ave, Red Deer, AB T4N 0M8</div>
                <div>Phone: (403) 340-1835</div>
                <div>Fax: (403) 340-2803</div>
              </div>
            </div>
          </div>



          <div className="col-12 col-md-6 mb-2">
            <div className="team team-summary team-summary-large">
              <div className="team-image">
                <img
                  alt="Dr. Chirag Shah"
                  className="img-fluid mb-2"
                  src="https://picsum.photos/200"
                />
              </div>
              <div className="team-meta">
                <h2 className="team-name">Dr. Chirag Shah</h2>
                <ul className="team-description"></ul>
              </div>
              <div className="team-content">
                <div>Suite 300-5 Richard Way SW, Calgary, AB T3E 7M8</div>
                <div>Office: 403-255-5561</div>
                <div>Fax: 403-255-7764</div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-2">
            <div className="team team-summary team-summary-large">
              <div className="team-image">
                <img
                  alt="Dr. Jag Anand"
                  className="img-fluid mb-2"
                  src="/images/team/jag_anand.png"
                />
              </div>
              <div className="team-meta">
                <h2 className="team-name">Dr. Jag Anand</h2>
                <ul className="team-description"></ul>
              </div>
              <div className="team-content">
                <p>
                  <a target="_blank" href="https://www.anandretinacentre.com/">
                    Website
                  </a>
                </p>

                <div>
                  Anand Retina Centre, S2675 36 St NE Suite 505, Calgary, AB T1Y
                  6H6
                </div>
                <div>Office: 403-452-6384</div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <h1>Edmonton</h1>
        <div className="row">
          <div className="col-12 col-md-6 mb-2">
            <div className="team team-summary team-summary-large">
              <div className="team-image">
                <img
                  alt="Dr. Linda Uniat"
                  className="img-fluid mb-2"
                  src="/images/team/linda.png"
                />
              </div>
              <div className="team-meta">
                <h2 className="team-name">Dr. Linda Uniat</h2>
              </div>
              <div className="team-content">
                <p>
                  <a
                    target="_blank"
                    href="https://www.edmontonretinacentre.ca/"
                  >
                    Website
                  </a>
                </p>
                <div>Edmonton Retina Centre</div>
                <div>Suite 402, Hys Centre</div>
                <div>11010 - 101 Street Edmonton AB T5H 4B9</div>
                <div>Office: 780-421-0465</div>
                <div>Fax: 780-425-5062</div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-2">
            <div className="team team-summary team-summary-large">
              <div className="team-image">
                <img
                  alt="Alberta Retina Consultants"
                  className="img-fluid mb-2"
                  src="/images/team/arc.png"
                />
              </div>
              <div className="team-meta">
                <h2 className="team-name">Alberta Retina Consultants</h2>
                <ul className="team-description">
                  <li>Dr. Mark Greve</li>
                  <li>Dr. Brad Hinz</li>
                  <li>Dr. Matt Tennant</li>
                  <li>Dr. Chad Baker</li>
                  <li>Dr. Riz Somani</li>
                  <li>Dr. David Ehmann</li>
                  <li>Dr. Mark Seamone</li>
                  <li>Dr. Paul Grewal</li>
                </ul>
              </div>
              <div className="team-content">
                <p>
                  <a target="_blank" href="https://www.alberta-retina.com ">
                    Website
                  </a>
                </p>
                <div>10924 107 Ave NW Edmonton AB T5H0X5</div>
                <div>Phone: 780-448-1801</div>
                <div>Fax: 780-448-1809</div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <h1>Medicine Hat</h1>
        <div className="row">
          <div className="col-12 col-md-6 mb-2">
            <div className="team team-summary team-summary-large">
              <div className="team-image">
                <img
                  alt="Dr. Ripan Chaudhary"
                  className="img-fluid mb-2"
                  src="/images/team/ripan_chaudhary.JPG"
                />
              </div>
              <div className="team-meta">
                <h2 className="team-name">Dr. Ripan Chaudhary</h2>
              </div>
              <div className="team-content">
                <div>#301, 623 4 Street SE</div>
                <div>Medicine Hat, AB T1A 0L1</div>
                <div>Office: 403-527-8545</div>
                <div>Fax: 403-527-8547</div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TeamQuery {
    intro: markdownRemark(fileAbsolutePath: { regex: "/(team.md)/" }) {
      html
      frontmatter {
        image
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
        title
      }
    }
  }
`;

export default Team;
