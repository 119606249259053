import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

const geoUrl = "https://gist.githubusercontent.com/mholkesvik/cc33baff209895fbc5195cfdc5272fdb/raw/f878898df5767dff15e03f1cb27cdac46e448fc9/canada.json"
// "https://gist.githubusercontent.com/mholkesvik/39e2ca811fdba32c64c36cb2f78feb44/raw/70ad0a858af20f1024bf896d4837ed7e4650128b/out.json";

const markers = [
  {
    markerOffset: -15,
    name: "Calgary",
    coordinates: [-114.0719, 51.0447],
  },
  {
    markerOffset: [-15],
    name: "Edmonton",
    coordinates: [-113.4938, 53.5461],
  },
  { markerOffset: 25, name: "Medicine Hat", coordinates: [-110.7034, 50.0292] },
  { markerOffset: -15, name: "Red Deer", coordinates: [-113.811241, 52.268112] },
  { markerOffset: -20, name: "Grande Prairie", coordinates: [-118.8027, 55.1667] }
];

const MapChart = () => {
  return (
    <ComposableMap
      projectionConfig={{
        rotate: [115, -55, -5],
        scale: 1750,
      }}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill={geo.properties.PRNAME !== "Alberta" ? "#EAEAEC" : "#EAEAEC"}
              stroke="#D6D6DA"
            />
          ))
        }
      </Geographies>
      {markers.map(({ name, coordinates, markerOffset }) => (
        <Marker key={name} coordinates={coordinates}>
          <circle r={10} fill="#F00" stroke="#fff" strokeWidth={2} />
          <text
            textAnchor="middle"
            y={markerOffset}
            style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
          >
            {name}
          </text>
        </Marker>
      ))}
    </ComposableMap>
  );
};

export default MapChart;
